import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {Button, Container, Grid, Stack} from '@mui/material';
import TextComponent from '../TextComponent';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * @props {object} accepts props object {open, closeDailog}
 * @return {Component} Full screen dailog
 */
export default function FullScreenDialog(props) {
  const {details, open, closeDialog} = props;

  const handleClose = () => {
    // setOpen(false);
    closeDialog();
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{position: 'relative'}}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container style={{padding: 24, overflow: 'scroll'}}>
          <Stack spacing={2} direction="column">
            <Typography variant="h3">{details && details.name}</Typography>
            <Typography variant="h6">{details && details.position}</Typography>
            <div>
              <TextComponent>{details && details.description}</TextComponent>
            </div>
            <div>
              <Typography variant="h6">Contact</Typography>
              <TextComponent>{details && details.contact}</TextComponent>
            </div>
            <div>
              <Stack spacing={2} direction="row">
                <Button color="secondary" variant="outlined">
                  Resume
                </Button>
                <Button color="secondary" variant="outlined">
                  Video
                </Button>
                <Button color="secondary" variant="outlined">
                  PDF
                </Button>
              </Stack>
            </div>
          </Stack>
        </Container>
      </Dialog>
    </div>
  );
}
