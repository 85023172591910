import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import image from '../assets/images/main.jpg';
import {Grid} from '@mui/material';
import {Content} from '../commons/commons';
import {makeStyles} from '@mui/styles';
import Aos from 'aos';
import 'aos/dist/aos.css';
import {backgroundColors} from '../constants/commonStyles';
import { links } from "../data/links";

const styles = makeStyles((theme) => ({
  iconStyle: {
    fontSize: '70px !important',
    backgroundColor: '#a7bba2',
    borderRadius: '50%',
    padding: '8px',
    margin: '0px 16px',
    width: '70px',
    height: '70px',
  },
  rootGrid: {
    margin: 20,
    marginBottom: 24,
    marginTop: 24,
  },
  bgGrid: {
    margin: 20,
    background: '#222222',
    marginBottom: 24,
    marginTop: 24,
    // marginLeft: '40px',
    padding: '24px',
  },
  imgGrid: {
    padding: 16,
  },
  container1: {
    background: backgroundColors.bg2,
  },
  container2: {
    background: backgroundColors.bg3,
  },
}));

/**
 * @param {Object} props takes in property object
 * @return {Component} returns outreach page
 */
export default function OutreachPage(props) {
  React.useEffect(() => {
    Aos.init({duration: 1000});

    return () => {
      // cleanup
    };
  }, []);

  const classes = styles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            height: '200px',
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
          }}
        >
          <Content
            variant="h2"
            textAlign="left"
            style={{
              position: 'relative',
              top: '50%',
              transform: 'translateY(-50%)',
              color: '#222222',
            }}
          >
            {' '}
            Outreach
          </Content>
        </Grid>

        <Grid item xs={12} md={12} className={classes.container1}>
          <Container>
            <Grid container>
              <Grid item xs={12} md={12}>
                <div className="scroll-bg">
                  <Grid container>
                    <Content
                      data-aos="fade-right"
                      variant="h3"
                      textAlign="left"
                    >
                      K-12
                    </Content>
                    <Grid container style={{padding: '24px'}}>
                      <Grid item xs={12} md={6}>
                        <Content variant="body1" textAlign="left">
                          Our group supports K-12 research through the SHINE program.
                          If you are a high school student and want to learn about K-12 opportunities 
                          please apply through <a href="https://viterbik12.usc.edu/shine/" target="_blank"> the USC SHINE program </a>
                          and select SCIP lab research projects.  
                        </Content>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <video
                          controls
                          width="100%"
                          style={{borderRadius: 10, width: '500px'}}
                        >
                          <source
                            src={'https://www.youtube.com/watch?v=VWL3AAu7Z7A'}
                            type="video/mp4"
                          />
                          Sorry, your browser doesnt support embedded videos.
                        </video>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Grid>

        <Grid item xs={12} md={12} className={classes.container2}>
          <Container style={{backgound: 'white'}}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <div className="scroll-bg">
                  <Grid container>
                    <Content
                      data-aos="fade-right"
                      variant="h3"
                      textAlign="left"
                    >
                      Undergraduate
                    </Content>
                    <Grid container style={{padding: '24px'}}>
                      <Grid item xs={12} md={6}>
                        <Content variant="body1" textAlign="left">
                          SCIP lab supports multiple undergraduate research
                          options. If you are an undergraduate interested in engaging with
                          the SCIP lab apply through 
                          <a href="https://viterbigradadmission.usc.edu/sure/" target="_blank"> the USC Viterbi SURE program </a>, and the 
                          <a href="https://viterbiundergrad.usc.edu/research/curve/" target="_blank"> USC Viterbi CURVE program </a>.
                          These programs are open to (and welcome) undergraduate students
                          from any US university. If you are a USC undergraduate email us directly with your CV and area of interest.   
                        </Content>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <video
                          controls
                          width="100%"
                          style={{borderRadius: 10, width: '500px'}}
                        >
                          <source
                            src={'https://www.youtube.com/watch?v=VWL3AAu7Z7A'}
                            type="video/mp4"
                          />
                          Sorry, your browser doesnt support embedded videos.
                        </video>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Grid>

        <Grid item xs={12} md={12} className={classes.container1}>
          <Container>
            <Grid container>
              <Grid item xs={12} md={12}>
                <div className="scroll-bg">
                  <Grid container>
                    <Content
                      data-aos="fade-right"
                      variant="h3"
                      textAlign="left"
                    >
                      MS Graduates
                    </Content>
                    <Grid container style={{padding: '24px'}}>
                      <Grid item xs={12} md={6}>
                        <Content variant="body1" textAlign="left">
                          We support students from diverse backgrounds
                          interested in AI and ML areas to pursue their MS at USC.
                          These MS Fellowships  cover tuition reimbursements
                          up to the FULL amount! Learn more about the fellowship at  
                          <a href="https://viterbigradadmission.usc.edu/meta-fellowship/" target="_blank"> the USC REAL@USC Fellows program </a>,
                          which also provides information on how to apply for MS through the VASE program. 
                          You must be a US domestic student to be eligible for applying. 
                          Contact VASE coordinators for more information. These Fellows are supported by the REAL@USC center which is
                          directed by Prof. Annavaram.                                
                        </Content>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <video
                          controls
                          width="100%"
                          style={{borderRadius: 10, width: '500px'}}
                        >
                          <source
                            src={'https://www.youtube.com/watch?v=VWL3AAu7Z7A'}
                            type="video/mp4"
                          />
                          Sorry, your browser doesnt support embedded videos.
                        </video>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Grid>

        <Grid item xs={12} md={12} className={classes.container2}>
          <Container>
            <Grid container>
              <Grid item xs={12} md={12}>
                <div className="scroll-bg">
                  <Grid container>
                    <Content
                      data-aos="fade-right"
                      variant="h3"
                      textAlign="left"
                    >
                      PHD
                    </Content>
                    <Grid container style={{padding: '24px'}}>
                      <Grid item xs={12} md={6}>
                        <Content variant="body1" textAlign="left">
                          Our group welcomes students in ML systems, Computer 
                          Architecture, Algorithm+Hardware co-optimizations and more.
                          If you are interested in applying to our PhD group, apply to 
                          ECE PhD program and select Prof. Annavaram's name. Then email 
                          him with your CV. If there is an opportunity and match we will 
                          contact you to setup a short call to discuss further.
                        </Content>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <video id="autoplay" muted playsinline controls width="100%" style={{ borderRadius: 10 }}>
                        <source src={links.HOME_PAGE_POTTERY} type="video/mp4" />
                        Sorry, your browser doesn't support embedded videos.
                        </video>
                    </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Grid>        
      </Grid>
    </React.Fragment>
  );
}
