import * as React from 'react';
import {Content, SubTitle} from './commons';
import FullScreenDialog from './full-screen-dialog/FullScreenDailog';

/**
 *
 * @param {*} props accepts props object
 * @return {component}
 */
export default function TitlebarBelowImageList(props) {
  const {imgSrc, name, position, rest} = props;

  const [open, setOpen] = React.useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <img
        src={imgSrc}
        loading="lazy"
        style={{
          width: '100%',
          width: '250px',
          height: '250px',
          borderRadius: '50%',
          cursor: 'pointer',
        }}
        onClick={openDialog}
      />
      <SubTitle variant="h5">{name}</SubTitle>
      <Content variant="h6">{position}</Content>
      <FullScreenDialog details={rest} open={open} closeDialog={closeDialog} />
    </>
  );
}
