import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import image from '../assets/images/main.jpg';
import {Grid, ImageList, ImageListItem} from '@mui/material';
import {BodyContent, Content} from '../commons/commons';
import {makeStyles} from '@mui/styles';
import Aos from 'aos';
import 'aos/dist/aos.css';
import {backgroundColors} from '../constants/commonStyles';
// import vid from '../assets/videos/ai.mp4';
import { links } from "../data/links"; 

const styles = makeStyles({
  borderRight: {
    borderRight: '1px solid rgba(255, 255, 255, 0.3)',
  },
  borderBottom: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
    height: '20px',
    width: '100%',
    margin: 'auto',
  },
  container1: {
    background: backgroundColors.bg2,
  },
  container2: {
    background: backgroundColors.bg3,
  },
  leftBorder1: {
    borderLeft: '2px solid orange',
  },
  leftBorder2: {
    borderLeft: '2px solid #9d9999',
  },
});

/**
 * @param {Object} props in props object
 * @return {Component} component OutPage Component
 */
export default function OutPage(props) {
  React.useEffect(() => {
    Aos.init({duration: 1000});

    return () => {
      // cleanup
    };
  }, []);

  const classes = styles();
  const gatheringImgList=[
    {
      title:"Chinese New Year Group Hangout in 2023",
      img:links.OUTSIDE_PAGE_OUTSIDE1
    },
    {
      title:"Dr. Hanieh Graduation Celebration",
      img:links.OUTSIDE_PAGE_OUTSIDE2
    },
    {
      title:"Professor Murali in Benguluru Half Marathon",
      img:links.OUTSIDE_PAGE_OUTSIDE3
    },
    {
      title:"Thanksgiving Celebration in 2023",
      img:links.OUTSIDE_PAGE_OUTSIDE4
    }]

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid data-aos="fade" container>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            height: '200px',
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
          }}
        >
          <Content
            variant="h2"
            textAlign="left"
            style={{
              position: 'relative',
              top: '50%',
              transform: 'translateY(-50%)',
              color: '#222222',
            }}
          >
            Outside of Academics
          </Content>
        </Grid>
        <Grid item xs={12} md={12} className={classes.container1}>
          <Container>
            <Grid container>
              <Grid item xs={12} md={12}>
                <div className="scroll-bg">
                  <Grid container>
                    <Content
                      data-aos="fade-right"
                      variant="h3"
                      textAlign="left"
                    >
                      {/* Our Group Gatherings */}
                    </Content>
                    <Grid container style={{padding: '24px'}}>
                      {/* <Grid item xs={12} md={6}>
                        <Item><img
                          data-aos="fade"
                          className={classes.img}
                          src={links.OUTSIDE_PAGE_OUTSIDE1}
                          alt={"ANNAVARAM GROUP IMAGE"}
                        />
                        
                      </Grid>
                      <Grid item xs={12} md={6}>
                      <img
                          data-aos="fade"
                          className={classes.img}
                          src={links.OUTSIDE_PAGE_OUTSIDE3}
                          alt={"ANNAVARAM GROUP IMAGE"}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <img
                          data-aos="fade"
                          className={classes.img}
                          src={links.OUTSIDE_PAGE_OUTSIDE2}
                          alt={"ANNAVARAM GROUP IMAGE"}
                        />
                      </Grid> */}
                      <ImageList cols={2} rowHeight={328}>
                      {gatheringImgList.map((item) => (
                        <>
                        <span style={{alignSelf: "center"}}>{item.title}</span>
                        <ImageListItem key={item.img}>
                          <img
                            src={`${item.img}?w=328&h=328&fit=crop`}
                            srcSet={`${item.img}?w=328&h=328&fit=crop&dpr=2 2x`}
                            alt={item.title}
                            loading="lazy"
                          />
                        </ImageListItem>
                        </>
                      ))}
                    </ImageList>
                      <div className={classes.borderBottom}></div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Grid>

        {/* <Grid item xs={12} md={12} className={classes.container2}>
          <Container>
            <Grid container>
              <Grid item xs={12} md={12}>
                <div className="scroll-bg">
                  <Grid container>
                    <Content
                      data-aos="fade-right"
                      variant="h3"
                      textAlign="left"
                    >
                      Places That Inspire:
                    </Content>
                    <Grid container style={{padding: '24px'}}>
                      <Grid item xs={12} md={6}>
                        <Content variant="body1" textAlign="left">
                          Some of the places that we have been over the years:   
                        </Content>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <video
                          controls
                          width="100%"
                          style={{borderRadius: 10, width: '500px'}}
                        >
                          <source
                            src={'https://www.youtube.com/watch?v=VWL3AAu7Z7A'}
                            type="video/mp4"
                          />
                          Sorry, your browser doesn't support embedded videos.
                        </video>
                      </Grid>
                      <div className={classes.borderBottom}></div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Grid> */}

        {/* <Grid item xs={12} md={12} className={classes.container1}>
          <Container>
            <Grid container>
              <Grid item xs={12} md={12}>
                <div className="scroll-bg">
                  <Grid container>
                    <Content
                      data-aos="fade-right"
                      variant="h3"
                      textAlign="left"
                    >
                      Literature That Inspires:
                    </Content>
                    <Grid container style={{padding: '24px'}}>
                      <Grid item xs={12} md={6}>
                        <Content variant="body1" textAlign="left">
                          Some of the fiction literature that we enjoy: 
                        </Content>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <video
                          controls
                          width="100%"
                          style={{borderRadius: 10, width: '500px'}}
                        >
                          <source
                            src={'https://www.youtube.com/watch?v=VWL3AAu7Z7A'}
                            type="video/mp4"
                          />
                          Sorry, your browser doesn't support embedded videos.
                        </video>
                      </Grid>
                      <div className={classes.borderBottom}></div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}
