import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {Grid, Typography} from '@mui/material';
import {Content, CustomButton, CustomTextField} from '../commons/commons';
import {makeStyles} from '@mui/styles';
import Aos from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';
import {REACT_APP} from '../constants';
import Footer from './Footer';

const styles = makeStyles({
  msgDiv: {
    'transition': 'box-shadow 0.5s',
    'cursor': 'pointer',
    '&:hover': {
      boxShadow: '1px 1px 8px grey',
      borderRadius: '5px',
    },
  },
});

/**
 *
 * @return {component} returns contact form component
 */
export default function ContactForm() {
  React.useEffect(() => {
    Aos.init({duration: 1000});

    return () => {
      // cleanup
    };
  }, []);

  const [contact, setContact] = React.useState({});
  const [submitObj, setSubmitObj] = React.useState({
    loading: false,
    error: false,
    success: false,
  });

  const handleChange = (event) => {
    setContact({
      ...contact,
      [event.target.name]: event.target.value,
    });
    setSubmitObj({
      error: false,
      success: false,
      loading: false,
    });
  };

  /**
   * Makes API call to backend server to record the user's message
   */
  const submit = () => {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (contact.email.match(mailformat)) {
      setSubmitObj({
        error: false,
        success: false,
        loading: true,
      });
      axios({
        method: 'POST',
        url: `${REACT_APP.SERVER_IP}/contact/publish`,
        responseType: 'json',
        data: {
          ...contact,
        },
      })
          .then((rsp) => {
          // setEvents(rsp.data);
            setSubmitObj({
              error: false,
              success: true,
              loading: false,
            });
            setContact({});
          })
          .catch((err) => {
            console.log('Something happended!!!');
            setSubmitObj({
              error: true,
              success: false,
              loading: false,
            });
          });
    } else {
      setSubmitObj({
        error: true,
        success: false,
        loading: false,
      });
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container style={{paddingTop: 20}}>
        <Box>
          <Content
            data-aos="fade-up"
            variant="h4"
            style={{textDecoration: 'underline', color: '#fff'}}
          >
            Contact Us
          </Content>
          <Content variant="h6" style={{color: '#fff'}}>
            {' '}
            Please let us know what you think
          </Content>
          <Grid container spacing={2} style={{padding: 50}}>
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    color="info"
                    id="outlined-adornment-password"
                    label="First Name"
                    variant="outlined"
                    name="firstName"
                    value={contact.firstName}
                    onChange={handleChange}
                    placeholder="First Name"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    color="info"
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    name="lastName"
                    value={contact.lastName}
                    onChange={handleChange}
                    placeholder="Last Name"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <CustomTextField
                color="info"
                id="outlined-basic"
                label="Email"
                name="email"
                value={contact.email}
                onChange={handleChange}
                placeholder="Email"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CustomTextField
                color="info"
                id="outlined-basic"
                label="Message"
                rows={5}
                multiline
                name="message"
                value={contact.message}
                onChange={handleChange}
                placeholder="Message"
              />
              {submitObj.success && (
                <Typography textAlign="center" variant="caption">
                  Recorded succesfully! We will contact you soon.
                </Typography>
              )}
              {submitObj.error && (
                <Typography textAlign="center" variant="caption">
                  Something's wrong, please check the input and try again
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <CustomButton onClick={submit} disabled={submitObj.loading}>
                {!submitObj.loading ? 'Submit' : 'Submitting...'}
              </CustomButton>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
