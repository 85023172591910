import {
  Button,
  Grid,
  Icon,
  Input,
  InputLabel,
  Typography,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import '@fontsource/montserrat';
import '@fontsource/source-sans-pro';
import {Link} from 'react-router-dom';
import TextComponent from './TextComponent';

export const color = '#2b2b2b';
export const secondaryTextColor = '#a7bba2';

export const CustomLink = styled(Link)(({theme}) => ({
  color: theme.palette.primary.contrastText,
  textDecoration: 'none',
}));

export const CustomButton = styled(Button)({
  'color': 'white',
  'background': '#2b2b2b',
  'borderRadius': 0,
  'padding': '16px',
  'fontWeight': 'bold',
  'fontSize': '1rem',
  'transition': 'all 0.5s',
  'margin': 16,
  '&:hover': {
    // background: 'white',
    // color: '#000',
  },
});

export const MenuButton = styled(Button)({
  'borderRadius': 0,
  'transition': 'all 0.5s',
  '&:hover': {
    borderTop: '3px solid #2b2b2b',
    background: 'transparent',
  },
});

export const IconListIcon = styled(Icon)({
  fontSize: '70px',
  background: secondaryTextColor,
  borderRadius: '50%',
  padding: '20px',
});

export const IconListDiv = styled(Grid)({
  textAlign: 'left',
  background: color,
  padding: '16px',
  borderRadius: '30px',
});

export const IconListHeader = styled(Typography)({
  textTransform: 'uppercase',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 'bold',
  color: secondaryTextColor,
});

export const IconListSubtitle = styled(Typography)({
  color: 'white',
});

export const CustomTextTypography = styled(Typography)({
  fontFamily: 'Montserrat, sans-serif',
  color: color,
  fontWeight: 'bold',
});

export const Header = styled(Typography)(({theme}) => ({
  'padding': theme.spacing(1),
  'margin': 8,
  'textTransform': 'uppercase',
  'color': theme.palette.primary.contrastText,
  '&:before': {
    content: '""',
    textAlign: 'center',
    width: '58px',
    height: '4px',
    display: 'block',
    marginBottom: '12px',
    backgroundColor: theme.palette.secondary.main,
    margin: '12px auto',
  },
}));

export const Title = styled(Typography)(({theme}) => ({
  'padding': theme.spacing(1),
  'margin': 8,
  'textTransform': 'uppercase',
  'color': theme.palette.primary.contrastText,
  '&:before': {
    content: '""',
    width: '58px',
    height: '4px',
    display: 'block',
    marginBottom: '12px',
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const SubTitle = styled(Typography)(({theme}) => ({
  'padding': theme.spacing(1),
  'margin': 8,
  'color': theme.palette.primary.contrastText,
  '&:before': {
    content: '""',
    textAlign: 'center',
    width: '58px',
    height: '4px',
    display: 'block',
    marginBottom: '12px',
    backgroundColor: theme.palette.secondary.main,
    margin: '12px auto',
  },
}));

export const Content = styled(Typography)(({theme}) => ({
  padding: theme.spacing(1),
  margin: 8,
  color: theme.palette.primary.contrastText,
}));

export const BodyContent = styled(Typography)(({theme}) => ({
  padding: theme.spacing(1),
  margin: 8,
  color: theme.palette.primary.body,
}));

export const CustomTextField = styled(Input)(({theme}) => ({
  'width': '100%',
  'margin': 16,
  'background': '#111',
  'opacity': 0.85,
  'padding': 16,
  'color': theme.palette.primary.contrastText,
  '&:focused': {
    color: theme.palette.primary.contrastText,
  },
}));

export const CustomInputLabel = styled(InputLabel)(({theme}) => ({
  'color': theme.palette.primary.contrastText,
  '&:focused': {
    color: theme.palette.primary.contrastText,
  },
}));

export const CustomContent = styled(TextComponent)(({theme}) => ({
  padding: theme.spacing(1),
  margin: 8,
  // color: theme.palette.primary.contrastText,
  color: '#222222',
  fontWeight: 'bold',
}));
