import research from '../assets/images/research.png';
import scalability from '../assets/images/scalability.png';
import outreach1 from '../assets/images/outreach1.png';
import outreach2 from '../assets/images/outreach2.png';
import outreach3 from '../assets/images/outreach3.png';
import outreach4 from '../assets/images/outreach4.png';
import annavaram_group_image from '../assets/images/annavaram_group_image.png';
import annavaram_group_image2 from '../assets/images/annavaram_group_image2.jpg';
import TogetherWebImg from "../assets/images/Together-Web.png";
import Superconducting from "../assets/images/superconducting.jpg";
import Machinelearning from "../assets/images/machinelearning.jpg";
import Nvmessd from "../assets/images/nvmessd.jpg";
import Supersocc from "../assets/images/supersocc.png";
import Mlaca from "../assets/images/mlaca.png";
import Graphssd from "../assets/images/graphssd.png";
import DarkKnight from "../assets/images/dark_knight.png"
import outside1 from "../assets/images/outside/outside1.jpg";
import outside2 from "../assets/images/outside/outside2.jpg";
import outside3 from "../assets/images/outside/outside3.jpg";
import outside4 from "../assets/images/outside/outside4.jpg";


import pillars from "../assets/videos/ChipHolding-Hand.mp4";
import pottery from "../assets/videos/HandsOnWorking-Pottery.mp4";

import logovideo from '../assets/videos/Sciplab-logo.mp4';
import TogetherWebvideo from "../assets/videos/Together-SCIPWeb.mp4";

export const links = {
  FACEBOOK: 'https://facebook.com',
  TWITTER: 'https://twitter.com',
  LINKEDIN: 'https://linkedin.com',
  LOGO: '',
  HOME_PAGE_VIDEO: logovideo,
  TOGETHER_VIDEO: TogetherWebvideo,
  TOGETHER_PNG: TogetherWebImg,
  HOME_PAGE_META: '',
  HOME_PAGE_RESEARCH_SCALABILTY: research,
  HOME_PAGE_RESEARCH_PRIVACY: scalability,
  HOME_PAGE_RESEARCH_OPTIMIZATION: scalability,
  HOME_PAGE_OUTREACH_K12: outreach1,
  HOME_PAGE_OUTREACH_UG: outreach2,
  HOME_PAGE_OUTREACH_MS: outreach3,
  HOME_PAGE_OUTREACH_PHD: outreach4,
  HOME_PAGE_OUTREACH_CD: outreach4,
  HOME_PAGE_OUTREACH_WORKSHOPS: outreach4,
  HOME_PAGE_TEACH_ML: '',
  HOME_PAGE_TEACH_ISE: '',
  HOME_PAGE_TEACH_NEW: '',
  HOME_PAGE_PILLARS_IMG: pillars, 
  HOME_PAGE_POTTERY: pottery, 
  ANNAVARAM_GROUP_IMAGE: annavaram_group_image,
  ANNAVARAM_GROUP_IMAGE2: annavaram_group_image2,
  ABOUT_PAGE_SUPERCONDUCTING: Superconducting,
  ABOUT_PAGE_ML: Machinelearning,
  ABOUT_PAGE_NVME: Nvmessd,
  RESEARCH_PAGE_SUPERCONDUCTING: Supersocc,
  RESEARCH_PAGE_ML: Mlaca,
  RESEARCH_PAGE_SSD: Graphssd,
  RESEARCH_PAGE_PRIVACY: DarkKnight,

  OUTSIDE_PAGE_OUTSIDE1: outside1,
  OUTSIDE_PAGE_OUTSIDE2: outside2,
  OUTSIDE_PAGE_OUTSIDE3: outside3,
  OUTSIDE_PAGE_OUTSIDE4: outside4,


};
