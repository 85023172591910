import React from 'react';

function TextComponent(props) {
  const {children, className} = props;

  return (
    <div className={className} dangerouslySetInnerHTML={{__html: children}} />
  );
}

export default TextComponent;
