import researchimg from '../assets/images/research.png';
import { links } from './links';

export const aboutData = {
  pillars: [
    {
      title: 'SUPERCONDUCTIVE COMPUTING',
      subtitle: 'Superconductive CPU microarchitecture, high capacity memories, encrypted computing with SCE',
      bgImg: links.ABOUT_PAGE_SUPERCONDUCTING
    },
    {
      title: 'ML SYSTEMS',
      subtitle:
        'Research in heterogeneous ML algorithm acceleration, system scalability',
      bgImg: links.ABOUT_PAGE_ML
    },
    {
      title: 'MEMORY AND STORAGE ACCELERATION',
      subtitle:
        'Near memory and storage acceleration',
        bgImg: links.ABOUT_PAGE_NVME
    },
    {
      title: 'SECURITY AND PRIVACY',
      subtitle: 'Hardware accelerated security and privacy, use of Intel SGX,',
      bgImg: links.HOME_PAGE_OUTREACH_WORKSHOPS
    }
  ],
};
