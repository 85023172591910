import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Container from '@mui/material/Container';
import image from '../assets/images/main.jpg';
import {Grid, Skeleton} from '@mui/material';
import {Content, CustomButton, Header} from '../commons/commons';
import TitlebarBelowImageList from '../commons/PersonComponent';
import {makeStyles} from '@mui/styles';
import {aboutData} from '../data/aboutPage';
import Aos from 'aos';
import 'aos/dist/aos.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import TeachCard from '../commons/teach-card/TeachCard';
import {REACT_APP} from '../constants';
import axios from 'axios';
import { links } from "../data/links";


const styles = makeStyles({
  iconStyle: {
    fontSize: '70px !important',
    backgroundColor: '#a7bba2',
    borderRadius: '50%',
    padding: '8px',
    margin: '0px 16px',
  },
  div: {
    'marginTop': 20,
    'padding': 24,
    'border': '1px solid #222222',
    'borderRadius': 10,
    'height': '100%',
    'transition': 'border 05.s',
    'cursor': 'pointer',
    '&:hover': {
      border: '1px solid orange',
    },
  },
  sliderContent: {
    top: '50%',
    transform: 'translateY(-50%)',
    marginLeft: '24px',
    position: 'absolute',
    background: '#222222',
    padding: 24,
  },
  div: {
    // border: '2px solid white',
    'height': '200px',
    'background': '#222222',
    'opacity': '0.80',
    // position: 'relative',
    'transform': 'translateY(-50%)',
    'transition': 'border 0.5s',
    'margin': 8,
    'cursor': 'pointer',
    'borderRadius': 5,
    '&:hover': {
      // border: '2px solid orange'
    },
  },
});

/**
 *
 * @return {component} returns about page component
 */
export default function AboutPage() {
  const [members, setMembers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    Aos.init({duration: 1000});

    getAllMembers();

    return () => {
      // cleanup
    };
  }, []);

  const getAllMembers = () => {
    setLoading(true);

    axios({
      method: 'GET',
      url: `${REACT_APP.SERVER_IP}/members`,
      responseType: 'json',
    })
        .then((rsp) => {
          /*not everyone has a priority*/
          const sorted = rsp.data.result.sort((a, b) => a.priority - b.priority);
          setMembers(sorted);
          setLoading(false);
        })
        .catch((err) => {
          setMembers([]);
          setLoading(false);
        });
  };

  const classes = styles();

  return (
    <React.Fragment>
    <CssBaseline />
    <Container maxWidth="sm" style={{ padding: 0, maxWidth: "100%" }}>
    <Box>
        <Grid container spacing={2} style={{ padding: 50 }}>
          <Grid
            data-aos="fade-right"
            item
            xs={12}
            md={6}
            style={{
              margin: "auto",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }}
          >
            <img
              data-aos="fade"
              className={classes.img}
              src={links.ANNAVARAM_GROUP_IMAGE}
              alt={"ANNAVARAM GROUP IMAGE"}
            />
          </Grid>

          <Grid
            data-aos="fade-left"
            item
            xs={12}
            md={6}
            style={{ textAlign: "left" }}
          >
            <Content variant="h3" style={{ color: "white" }}>
              About SCIP LAB
            </Content>

            <Content
              variant="body1"
              textAlign="left"
              style={{ color: "white" }}
            >
            SCIP Lab was established in 2007 at the Ming-Hsieh Department of ECE at USC. 
            The lab is lead by Prof. Murali Annavaram, Dean's Professor in the 
            Ming-Hsieh Department of Electrical and Computer Engineering at the University of Southern California 
            from 2007. He also holds a joint appointment with the department of Computer Science.  He is the 
            founding Director of the USC-Meta center for Research and Education in AI and Learning. For his numerous 
            publications, he was inducted into the hall of fame at three top-tier computer architecture conference venues, 
            ISCA, HPCA and MICRO. He held the Robert G. and Mary G. Lane Early Career Chair until 2017. 
            </Content>

            <Content variant="body2" style={{color: 'White'}}>
            Prior to his appointment at USC he worked at Intel Microprocessor Research Labs from 2001 to 2007 working
            on energy efficient system design and die-stacking architectures. In 2007 he was a visiting researcher at 
            the Nokia Research Center, Palo Alto working on mobile phone-based wireless traffic sensing using virtual trip lines. 
            </Content>

          </Grid>
        </Grid>
      </Box>
  
        <Box style={{background: '#0C1821', paddingTop: 30}}>
          <Content variant="h4">Current Research Vectors</Content>
          <Grid container spacing={2}>
            {aboutData.pillars.map((item) => {
              return (
                <Grid item xs={12} md={3}>
                  <TeachCard
                    title={item.title}
                    description={item.subtitle}
                    link={item.link}
                    bgImg={item.bgImg}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>

        <Grid style={{background: '#0C1821'}}>
          <Box>
            <Grid container spacing={2} style={{padding: 50}}>
              <Grid item xs={12} md={12} style={{textAlign: 'center'}}>
                <Header variant="h4">OUR TEAM</Header>
                <Grid container spacing={2}>
                  {!loading && members.map(
                      (item, index) =>
                        item.dept &&
                      item.dept === 'SCIP_RESEARCH_TEAM'? (
                          <Grid data-aos="fade" item xs={12} md={4}>
                            <TitlebarBelowImageList
                              name={item.name}
                              position={item.position}
                              rest={item}
                              imgSrc={item.profilePic || ''}
                            />
                          </Grid>
                        ):null,
                  )} 
                  {/* remove dept condition */}
                  {/* {!loading && members.map(
                      (item, index) =>
                      (
                          <Grid data-aos="fade" item xs={12} md={4}>
                            <TitlebarBelowImageList
                              name={item.name}
                              position={item.position}
                              rest={item}
                              imgSrc={item.imgSrc || ''}
                            />
                          </Grid>
                        ),
                  )}                  */}
                  {
                    loading && (
                      <Box sx={{pt: 0.5}}>
                        <Skeleton variant="rectangular" width={300} height={300} />
                        <Skeleton width="100" />
                      </Box>
                    )
                  }
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Grid container spacing={2} style={{padding: 50}}>
              <Grid item xs={12} md={12} style={{textAlign: 'center'}}>
                <Header variant="h4">MEET OUR UNDERGRADUATE TEAM</Header>
                <Grid container spacing={2}>
                  {!loading && members.map(
                      (item, index) =>
                        item.dept &&
                      item.dept === 'SCIPLAB_TEAM_UG' && (
                          <Grid data-aos="fade" item xs={12} md={4}>
                            <TitlebarBelowImageList
                              name={item.name}
                              position={item.position}
                              rest={item}
                              imgSrc={item.profilePic || ''}
                            />
                          </Grid>
                        ),
                  )}
                  {
                    loading && (
                      <Box sx={{pt: 0.5}}>
                        <Skeleton variant="rectangular" width={300} height={300} />
                        <Skeleton width="20%" />
                      </Box>
                    )
                  }
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
