import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createTheme, ThemeProvider} from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          color: '#000',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          'color': '#000',
          '&:focused': {
            color: '#000',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#000000',
      contrastText: '#fff',
      bgColor: '#222222',
      body: '#939191',
    },
    secondary: {
      main: '#a7bba2',
    },
    success: {
      main: '#FFA500',
    },
    info: {
      main: '#fff',
    },
    mode: 'dark',
    divider: 'rgba(255, 255, 255, .125)',
  },
  shape: {
    borderRadius: 10,
  },
});


ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
