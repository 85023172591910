import * as React from 'react';
import {month} from '../../constants/GeneralConstants';
import './announcement-card.css';
import dummy from '../../assets/images/dummy_card.png';
import bgimg from '../../assets/images/REALlogo.png';
import moment from 'moment';
import EventDescription from '../event-description/EventDescription';
/**
 *
 * @param {object} props
 * @return {component} returns Announcement card component
 */
export default function AnnouncementCard(props) {
  const {title, summary, date, description, link, bgImg} = props;
  const [open, setOpen] = React.useState(false);
  const [event, setEvent] = React.useState({});


  const day = moment(date).format('DD');   
  const mnth = moment(date).format('MMM');  

  const handleDialog = () => {
    setOpen(true);
    setEvent(props);
  }

  const closeDialog = () => {
    setOpen(false);
    setEvent({});
  }
  
  return (
    <>
      <figure className="snip1347">
        <img src={bgImg ? bgImg : dummy} alt="sample87" />
        <div className="date">{day + ' ' + mnth}</div>
        <figcaption>
          <h4>{title}</h4>
          <p>{summary}</p>
          <a href="#/events" className="read-more">Read More</a>
        </figcaption>
      </figure>
      <EventDescription open={open} event={event} closeDialog={closeDialog} />
    </>
  );
}
