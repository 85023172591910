import * as React from "react";
import "./teach-card.css";

export default function TeachCard(props) {
  const { title, description, link, bgImg } = props;

  return (
    <>
      <figure className="snip1580">
        <img
          src={bgImg}
          alt="profile-sample2"
          height="210"
        />
        <figcaption>
          <h3>{title}</h3>
          <h5>{description}</h5>
        </figcaption>
        <a href={"#/research"}></a>
      </figure>
    </>
  );
}
