import * as React from 'react';
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import TextComponent from './TextComponent';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import {Button, Grid} from '@mui/material';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  'border': `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  'background': '#222222',
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}} />}
    {...props}
  />
))(({theme}) => ({
  'backgroundColor':
    theme.palette.mode === 'dark' ?
      'rgba(255, 255, 255, .05)' :
      'rgba(0, 0, 0, .03)',
  'flexDirection': 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(255, 255, 255, .125)',
  textAlign: 'left',
}));

const CustomButton = styled(Button)(({theme}) => ({
  'color': 'white',
  'border': '1px solid',
  'borderRadius': 5,
  'margin': 8,
  'transition': 'all 0.4s',
  '&:hover': {
    border: '1px solid orange',
  },
}));

/**
 *
 * @param {*} props input properties from parent component
 * @return {component} returns accordian list component
 */
export default function CustomAccordian(props) {
  const [expanded, setExpanded] = React.useState('');
  const {papers} = props;

  const handleChange = (panel) => (_event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {papers.map((item, index) => (
        <Accordion
          key={`accordian-${index}`}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
        >
          <AccordionSummary
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <TextComponent>{item.title}</TextComponent>
          </AccordionSummary>
          <AccordionDetails
            dangerouslySetInnerHTML={{__html: item.content}}
          ></AccordionDetails>
          <Grid
            item
            xs={12}
            md={12}
            style={{marginLeft: 16, textAlign: 'left'}}
          >
            <CustomButton
              href={item.pdfLink}
              variant="outlined"
              startIcon={<PictureAsPdfIcon />}
            >
              PDF
            </CustomButton>
            <CustomButton
              href={item.videoLink}
              variant="outlined"
              startIcon={<VideoLibraryIcon />}
            >
              Video
            </CustomButton>
            <CustomButton
              href={item.slidesLink}
              variant="outlined"
              startIcon={<SlideshowIcon />}
            >
              Slides
            </CustomButton>
          </Grid>
        </Accordion>
      ))}
    </div>
  );
}
