/* eslint-disable require-jsdoc */
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import image from '../assets/images/main.jpg';
import {Box, Button, Grid, Skeleton} from '@mui/material';
import {Content} from '../commons/commons';
import {makeStyles} from '@mui/styles';
import Aos from 'aos';
import 'aos/dist/aos.css';
import CustomAccordian from '../commons/Accordian';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import research from '../assets/images/research.png';
import {REACT_APP} from '../constants';
import axios from 'axios';
import {backgroundColors} from '../constants/commonStyles';
import {styled} from '@mui/material/styles';
import { links } from "../data/links";

const CustomButton = styled(Button)(({theme}) => ({
  'color': 'white',
  'border': '1px solid',
  'borderRadius': 5,
  'margin': 8,
  'transition': 'all 0.4s',
  '&:hover': {
    border: '1px solid orange',
  },
}));

const styles = makeStyles({
  borderRight: {
    borderRight: '1px solid rgba(255, 255, 255, 0.3)',
  },
  borderBottom: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
    height: '20px',
    width: '100%',
    margin: 'auto',
  },
  container1: {
    background: backgroundColors.bg2,
  },
  container2: {
    background: backgroundColors.bg3,
  },
  textStyle: {
    color: 'white',
    textAlign: 'left',
  },
});

/**
 *
 * @param {*} props
 * @return {Component} returns research page component
 */
export default function ResearchPage(props) {
  const [mlPapers, setMlPapers] = React.useState([]);
  const [scPapers, setScPapers] = React.useState([]);
  const [memPapers, setMemPapers] = React.useState([]);
  const [spPapers, setSpPapers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    Aos.init({duration: 1000});
    getAllResearchPapers();

    return () => {
      // cleanup
    };
  }, []);

  const getAllResearchPapers = () => {
    setLoading(true);
    axios({
      method: 'GET',
      url: `${REACT_APP.SERVER_IP}/research_papers`,
      responseType: 'json',
    })
        .then((rsp) => {
          setScPapers(rsp.data.result.filter(paper=>paper.tag==='Superconductive Computing'));
          setMlPapers(rsp.data.result.filter(paper=>paper.tag==='ML Systems'));
          setMemPapers(rsp.data.result.filter(paper=>paper.tag==='Memory & Storage Acceleration'));
          setSpPapers(rsp.data.result.filter(paper=>paper.tag==='Security & Privacy'));
          setLoading(false);
        })
        .catch((err) => {
          setScPapers([]);
          setMlPapers([]);
          setMemPapers([]);
          setSpPapers([]);
          setLoading(false);
        });
  };

  const classes = styles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            height: '200px',
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
          }}
        >
          <Content
            variant="h2"
            textAlign="left"
            style={{
              position: 'relative',
              top: '50%',
              transform: 'translateY(-50%)',
              color: '#222222',
            }}
          >
            {' '}
            Research
          </Content>
        </Grid>
        <Grid item xs={12} md={12} className={classes.container1}>
          <Container>
            <Grid container>
              <Grid item xs={12} md={12}>
                <div className="scroll-bg">
                  <Grid container>
                    <Content variant="h3" textAlign="left">
                    SUPERCONDUCTIVE COMPUTING
                    </Content>
                    <Grid container style={{padding: '24px'}}>
                      <Grid data-aos="fade-right" item xs={12} md={6}>
                        <Content variant="body1" textAlign="left">
                          Superconductive computing electronics (SCE) with focus on general purpose
                          computing with Josephson Junctions.
                        </Content>
                        <Content variant="body1" textAlign="left">
                          Custom hardware accelerators using SCE.
                        </Content>

                        <Content variant="body1" textAlign="left">
                          Integrate multiple SCE components for a SuperSOCC:
                        </Content>

                        <ul className={classes.textStyle}>
                          <li>High-density destructive read out memories (super memories)</li>
                          <li>DNN accelerators</li>
                          <li>Interface technologies for higher temperature systems</li>
                          <li>Dealing with robustness and programmability challenges</li>
                        </ul>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <img
                          data-aos="fade-left"
                          className={classes.img}
                          src={links.RESEARCH_PAGE_SUPERCONDUCTING}
                        alt={"ANNAVARAM GROUP IMAGE"}
                        />

                      </Grid>
                      <div className={classes.borderBottom}></div>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{padding: 24, textAlign: 'left'}}
                      >
                        <Content variant="h6" textAlign="left">
                          Related Papers
                        </Content>
                        {!loading && scPapers && scPapers.length > 0 && (
                          <>
                            <CustomAccordian papers={scPapers} />
                            <CustomButton
                              href="#/papers"
                              variant="outlined"
                              endIcon={<ChevronRightIcon />}
                            >
                        More Papers
                            </CustomButton>
                          </>
                        )}
                        {
                          loading ? (
                            <Box sx={{pt: 0.5}}>
                              <Skeleton />
                              <Skeleton width="60%" />
                            </Box>
                          ) : ( scPapers <= 0 && 'No Papers' )
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid item xs={12} md={12} className={classes.container2}>
          <Container>
            <Grid container>
              <Grid item xs={12} md={12}>
                <div className="scroll-bg">
                  <Grid container>
                    <Content variant="h3" textAlign="left">
                      ML SYSTEMS
                    </Content>
                    <Grid container style={{padding: '24px'}}>
                      <Grid data-aos="fade-right" item xs={12} md={6}>
                        <Content variant="body1" textAlign="left">
                          Design of ML accelerators for heterogeneous ML models,
                          under varying energy and cost constraints.
                          We focus on the following specific topics:
                        </Content>

                        <ul className={classes.textStyle}>
                          <li>Handling of diverse models in a single accelerator fabric</li>
                          <li>Carbon footprint constraints</li>
                          <li>Scalability of large model training</li>
                          <li>Training and inference at the edge</li>
                        </ul>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <img
                          data-aos="fade-left"
                          className={classes.img}
                          src={links.RESEARCH_PAGE_ML}
                        alt={"MLACA PAGE"}
                        />

                      </Grid>
                      <div className={classes.borderBottom}></div>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{padding: 24, textAlign: 'left'}}
                      >
                        <Content variant="h6" textAlign="left">
                          Related Papers
                        </Content>
                        {!loading && mlPapers && mlPapers.length > 0 && (
                          <>
                            <CustomAccordian papers={mlPapers} />
                            <CustomButton
                              href="#/papers"
                              variant="outlined"
                              endIcon={<ChevronRightIcon />}
                            >
                        More Papers
                            </CustomButton>
                          </>
                        )}
                        {
                          loading ? (
                            <Box sx={{pt: 0.5}}>
                              <Skeleton />
                              <Skeleton width="60%" />
                            </Box>
                          ) : ( mlPapers <= 0 && 'No Papers' )
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid item xs={12} md={12} className={classes.container1}>
          <Container>
            <Grid container>
              <Grid item xs={12} md={12}>
                <div className="scroll-bg">
                  <Grid container>
                    <Content variant="h3" textAlign="left">
                      MEMORY & STORAGE ACCELERATION
                    </Content>
                    <Grid container style={{padding: '24px'}}>
                      <Grid data-aos="fade-right" item xs={12} md={6}>
                        <Content variant="body1" textAlign="left">
                          Accelerate big data workloads using semantically aware memory 
                          and storage systems. The goal is to expose the application layer
                          semantic knowledge to the data layout and access mechanisms. 
                          Specifically, our work focuses on: 
                        </Content>

                        <ul className={classes.textStyle}>
                          <li>Graph analytics acceleration </li>
                          <li>End-to-end speedup of ML through training data pre-processing </li>
                          <li>Memory system designs for massive ML models </li>
                          <li>Failure recovery in ML through fast model checkpointing</li>
                        </ul>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <img
                          data-aos="fade-left"
                          className={classes.img}
                          src={links.RESEARCH_PAGE_SSD}
                        alt={"GRAPH SSD"}
                        />

                      </Grid>
                      <div className={classes.borderBottom}></div>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{padding: 24, textAlign: 'left'}}
                      >
                        <Content variant="h6" textAlign="left">
                          Related Papers
                        </Content>
                        {!loading && memPapers && memPapers.length > 0 && (
                          <>
                            <CustomAccordian papers={memPapers} />
                            <CustomButton
                              href="#/papers"
                              variant="outlined"
                              endIcon={<ChevronRightIcon />}
                            >
                            More Papers
                            </CustomButton>
                          </>
                        )}
                        {
                          loading ? (
                            <Box sx={{pt: 0.5}}>
                              <Skeleton />
                              <Skeleton width="60%" />
                            </Box>
                          ) : ( memPapers <= 0 && 'No Papers' )
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Grid>

<Grid item xs={12} md={12} className={classes.container1}>
          <Container>
            <Grid container>
              <Grid item xs={12} md={12}>
                <div className="scroll-bg">
                  <Grid container>
                    <Content variant="h3" textAlign="left">
                      SECURITY & PRIVACY
                    </Content>
                    <Grid container style={{padding: '24px'}}>
                      <Grid data-aos="fade-right" item xs={12} md={6}>
                        <Content variant="body1" textAlign="left">
                          Our work on security and privacy uses hardware provided
                          capabilities, such as TEEs, to enable execution on provably private data. 
                          We also work on new class of security problems (and associated Information
                          leakage) that arise due to address disclosure, even when data is invisible.
                          In particular, our current focus is on: 
                        </Content>

                        <ul className={classes.textStyle}>
                          <li>Expose privacy leaks in ML systems</li>
                          <li>Use of TEE for private ML training </li>
                          <li>Obfuscated RAM designs for training recommendation systems </li>
                          <li>Hybrid TEE and multi-party computing</li>
                        </ul>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <img
                          data-aos="fade-left"
                          className={classes.img}
                          src={links.RESEARCH_PAGE_PRIVACY}
                        alt={"PRIVACY LEAKS"}
                        />

                      </Grid>
                      <div className={classes.borderBottom}></div>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{padding: 24, textAlign: 'left'}}
                      >
                        <Content variant="h6" textAlign="left">
                          Related Papers
                        </Content>
                        {!loading && spPapers && spPapers.length > 0 && (
                          <>
                            <CustomAccordian papers={spPapers} />
                            <CustomButton
                              href="#/papers"
                              variant="outlined"
                              endIcon={<ChevronRightIcon />}
                            >
                            More Papers
                            </CustomButton>
                          </>
                        )}
                        {
                          loading ? (
                            <Box sx={{pt: 0.5}}>
                              <Skeleton />
                              <Skeleton width="60%" />
                            </Box>
                          ) : ( spPapers <= 0 && 'No Papers' )
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Grid>

      </Grid>
    </React.Fragment>
  );
}
