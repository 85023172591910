import {Box, Chip, Container, Grid, Skeleton, Stack, Typography} from '@mui/material';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {REACT_APP} from '../../constants';
import CustomAccordian from '../Accordian';

const tags = ['All', 'ML Systems', 'Superconductive Computing', 'Memory & Storage Acceleration', 'Security & Privacy'];

/**
 *
 * @return {component} returns papers list component
 */
export default function PapersList() {
  const [papers, setPapers] = useState({
    selectedIndex: -1,
    papersList: [],
    copy: [],
  });
  //   const [papers, setPapers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllResearchPapers();

    return () => {};
  }, []);

  const getAllResearchPapers = () => {
    setLoading(true);
    axios({
      method: 'GET',
      url: `${REACT_APP.SERVER_IP}/research_papers`,
      responseType: 'json',
    })
        .then((rsp) => {
        //   papersCopy = [rsp.data];
          setPapers({
            ...papers,
            papersList: rsp.data.result,
            copy: rsp.data.result,
          });
          setLoading(false);
        })
        .catch((err) => {
          setPapers({
            papersList: [],
            copy: [],
            selectedIndex: -1,
          });
          setLoading(false);
        });
  };

  const filterList = (index) => (event) => {
    if (index === 0) {
      setPapers({
        ...papers,
        selectedIndex: 0,
        papersList: papers.copy,
      });
    } else if (index > 0 && papers.copy && Array.isArray(papers.copy)) {
      const filtered = papers.copy.filter(
          (item) =>
            item.tag === tags[index]);
      if (Array.isArray(filtered)) {
        setPapers({
          ...papers,
          selectedIndex: index,
          papersList: filtered,
        });
      }
    }
  };

  return (
    <Container style={{paddingTop: 50}}>
      <Typography variant="h4" align="left">Research Papers</Typography>
      <Stack direction="row" spacing={1} style={{margin: 16, marginTop: 24}}>
        {tags.map((item, index) => (
          <Chip
            key={`badge-${index}`}
            label={item}
            name={item}
            variant={papers.selectedIndex === index ? 'contained' : 'outlined'}
            color={papers.selectedIndex === index ? 'success' : 'info'}
            onClick={filterList(index)}
          />
        ))}
      </Stack>
      {loading ? (
        <Box sx={{pt: 0.5}}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      ) : (
        <>
          {papers.papersList && papers.papersList.length > 0 ? (
            <CustomAccordian papers={papers.papersList} />
          ) : (
            <Typography variant="h6" align="left">No papers to show</Typography>
          )}
        </>
      )}
    </Container>
  );
}
