import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {REACT_APP} from '../constants';

/**
 *
 * @return {component} returns papers list component
 */
export default function EventsPage() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = React.useState('');

  useEffect(() => {
    getAllEvents();

    return () => {};
  }, []);

  const handleChange = (panel) => (_event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getAllEvents = () => {
    setLoading(true);
    axios({
      method: 'GET',
      url: `${REACT_APP.SERVER_IP}/events`,
      responseType: 'json',
    })
        .then((rsp) => {
          if (rsp && rsp.data.result) {
            const sorted = rsp.data.result.sort((a,b)=>new Date(b.date) - new Date(a.date));
            setEvents(sorted);
            setLoading(false);
          }
        })
        .catch((err) => {
          if (err) {
            setEvents([]);
            setLoading(false);
          }
        });
  };

  return (
    <Container style={{paddingTop: 50}}>
      <Typography variant="h4" align="left">
        Events
      </Typography>
      {loading ? (
        <Box sx={{pt: 0.5}}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      ) : (
        <>
          {events && events.length > 0 ? (
            events.map((item, index) => (
              <Accordion
                key={`accordian-${index}`}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <AccordionSummary
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Stack direction="column">
                    <Typography color="secondary" variant="h6" align="left">
                      {item.title}
                    </Typography>
                    <Typography variant="caption" align="left">
                      {moment(item.date).format("DD MMM, YYYY")}
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails
                  style={{textAlign: 'left'}}
                  dangerouslySetInnerHTML={{__html: item.description}}
                ></AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Typography variant="h6" align="left">
              No events to show
            </Typography>
          )}
        </>
      )}
    </Container>
  );
}
