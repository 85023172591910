import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import AboutPage from './AboutPage';
import OutreachPage from './OutreachPage';
import ResearchPage from './ResearchPage';
import OutPage from './OutPage';
import HomePage from './HomePage';
import {Badge, IconButton} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {links} from '../data/links';
import ContactPage from './ContactPage';
import PapersList from '../commons/papers-list/PapersList';
import EventsPage from './EventsPage';
import homepage_topright_logo from '../assets/images/sciplab-logo.png';
import logovideo from '../assets/videos/REALlogo.mp4';

const pages = [
  {
    label: 'Home',
    link: '/',
  },
  {
    label: 'About',
    link: '#/about',
  },
  {
    label: 'Research',
    link: '#/research',
  },
  {
    label: 'Outreach',
    link: '#/outreach',
  },
  {
    label: 'Outside',
    link: '#/outside',
  },
  {
    label: 'Contact',
    link: '#/contact',
  },
];

function ScrollTop(props) {
  const {children, window} = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
        '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{position: 'fixed', bottom: 16, right: 16}}
      >
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

/**
 *
 * @param {props} props takes in property object
 * @return {Component} return top nav bar component
 */
export default function NewAppBar(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar style={{background: '#222222', boxShadow: 'none'}}>
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            style={{fontWeight: 'bolder'}}
          >
            {<img src={homepage_topright_logo} height='100px'/>}
             {/*<img src={logo1} height='50px'/> */}
          </Typography>
          {/* <img src={logo} height='100px' /> */}
          <Box
            sx={{
              flexGrow: 1,
              display: {xs: 'none', md: 'flex'},
              justifyContent: 'center',
            }}
          >
            <ul className="snip1143">
              {pages.map((item) => (
                <li>
                  <a href={item.link} data-hover={item.label}>
                    {item.label}
                  </a>
                </li>
              ))}
            </ul>
          </Box>
          <Box sx={{display: {xs: 'none', md: 'flex'}}}>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              // color="inherit"
              style={{color: 'white'}}
              href={links.FACEBOOK}
            >
              <Badge color="error">
                <FacebookIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              style={{color: 'white'}}
              href={links.TWITTER}
            >
              <Badge color="error">
                <TwitterIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              style={{color: 'white'}}
              href={links.LINKEDIN}
            >
              <LinkedInIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <Routes>
        <Route path="/about" element={<AboutPage />}></Route>
        <Route path="/outreach" element={<OutreachPage />}></Route>
        <Route path="/research" element={<ResearchPage />}></Route>
        <Route path="/outside" element={<OutPage />}></Route>
        <Route path="/contact" element={<ContactPage />}></Route>
        <Route path="/papers" element={<PapersList />}></Route>
        <Route path="/events" element={<EventsPage />}></Route>
        <Route path="/" element={<HomePage />}></Route>
      </Routes>
      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}
