import './App.css';
import Footer from './modules/Footer';
import React from 'react';
import {
  HashRouter as Router,
} from 'react-router-dom';
import NewAppBar from './modules/NewAppBar';

function App() {
  return (
    <>
      <div className="App">

        <Router basename="/">
          <NewAppBar />
        </Router>
      </div>
    </>
  );
}

export default App;
