import { Button, Typography } from '@mui/material';
import {makeStyles} from '@mui/styles';
import moment from 'moment';
import * as React from 'react';
import {month} from '../../constants/GeneralConstants';
import EventDescription from '../event-description/EventDescription';
import TextComponent from '../TextComponent';
import './event-card.css';

const styles = makeStyles({
  text: {
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    overflow: 'hidden',
    maxHeight: '3.6em',
    lineHeight: '1.8em',
  },
});

/**
 *
 * @param {object} props
 * @return {component} return event card component
 */
export default function EventCard(props) {
  const {title, description, date} = props;
  const [open, setOpen] = React.useState(false);
  const [event, setEvent] = React.useState({});

  const day = moment(date).format('DD');   
  const mnth = moment(date).format('MMM');      

  const classes = styles();

  const handleDialog = () => {
    setOpen(true);
    setEvent(props);
  }

  const closeDialog = () => {
    setOpen(false);
    setEvent({});
  }

  return (
    <>
      <figure className="snip1216">
        <figcaption>
          <div className="date">
            <span className="day">{day}</span>
            <span className="month">{mnth}</span>
          </div>
          <Typography className="title" variant="h4">{title}</Typography>
          <TextComponent className="desc" variant="body2" noWrap>{description}</TextComponent>
        </figcaption>
        <a href="javascript:;" onClick={handleDialog}>More</a>
      </figure>
      <EventDescription open={open} event={event} closeDialog={closeDialog} />
    </>
  );
}
