import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {Badge, Grid, IconButton} from '@mui/material';
import {Content, Header} from '../commons/commons';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function Footer() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm" style={{padding: 0, maxWidth: '100%'}}>
        <Box sx={{background: 'black'}}>
          <Grid container spacing={2} style={{padding: 50}}>
            <Grid item xs={12} md={12} style={{textAlign: 'center'}}>
              <Header variant="h5">USC-META Center</Header>

              <Box
                sx={{
                  display: {xs: 'none', md: 'flex'},
                  justifyContent: 'center',
                }}
              >
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  style={{color: '#383838'}}
                >
                  <Badge color="error">
                    <TwitterIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  style={{color: '#383838'}}
                >
                  <Badge color="error">
                    <FacebookIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  style={{color: '#383838'}}
                >
                  <LinkedInIcon />
                </IconButton>
              </Box>
              <Content variant="subtitle2">
                2022 USC-META. All rights reserved. | Privacy Policy
              </Content>
              {/* <Content variant="subtitle2">
                Designed and Developed by Ravish K G
              </Content> */}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}
