import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import image from "../assets/images/main.jpg";
import { Button, Grid, Skeleton } from "@mui/material";
import { Content, CustomButton } from "../commons/commons";
import {
  homeData,
  outreachSectionData,
  teachSectionData,
} from "../data/homePage";
import Aos from "aos";
import "aos/dist/aos.css";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
// import vid from '../assets/videos/ai.ƒ';
import EventCard from "../commons/event-card/EventCard";
import TeachCard from "../commons/teach-card/TeachCard";
import OutreachCard from "../commons/outreach-card/OutreachCard";
import { REACT_APP } from "../constants";
import { backgroundColors } from "../constants/commonStyles";
import { links } from "../data/links";
import Tweet from "../commons/Tweet";
import AnnouncementCard from "../commons/announcement-card/AnnouncementCard";
import Footer from "./Footer";
import EventDescription from "../commons/event-description/EventDescription";

const styles = makeStyles({
  borderBottom: {
    borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
    height: "20px",
    width: "90%",
    margin: "auto",
  },
  linkText: {
    color: "white",
    opacity: "0.5",
    fontWeight: "bolder",
    fontSize: "1.75rem",
    transition: "all 0.5s",
    textAlign: "left",
    paddingLeft: "20px",
    cursor: "pointer",
    "&:hover": {
      opacity: 1,
    },
  },
  img: {
    transition: "all 0.3s",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    width: "100%",
  },
  container1: {
    background: backgroundColors.bg2,
    padding: 30,
    minHeight: 400,
  },
  container2: {
    background: backgroundColors.bg3,
    padding: 30,
    minHeight: 400,
  },
});

const imgs = [
  {
    label: "scalability",
    value: links.HOME_PAGE_RESEARCH_SCALABILTY,
  },
  {
    label: "privacy",
    value: links.HOME_PAGE_RESEARCH_PRIVACY,
  },
  {
    label: "optimization",
    value: links.HOME_PAGE_RESEARCH_OPTIMIZATION,
  },
];

/**
 *
 * @return {component} return home page component
 */
export default function HomePage() {
  const [imgValue, setImgValue] = React.useState(imgs[0]["value"]);
  const [events, setEvents] = React.useState([]);
  const [announcement, setAnnouncement] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    Aos.init({ duration: 1000 });
    getAllContent();

    return () => {
      // cleanup
    };
  }, []);

  const getAllContent = () => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${REACT_APP.SERVER_IP}/events`,
      responseType: "json",
    })
      .then((rsp) => {
        const events = rsp.data.result.filter(
          (item) => item.isAnnouncement !== "ANNOUNCEMENT"
        ).sort((a,b)=>new Date(b.date) - new Date(a.date));
        const announcement = rsp.data.result.filter(
          (item) => item.isAnnouncement === "ANNOUNCEMENT"
        ).sort((a,b)=>new Date(b.date) - new Date(a.date));
        setEvents(events);
        setAnnouncement(announcement);
        setLoading(false);
      })
      .catch((err) => {
        setEvents([]);
        setAnnouncement([]);
        setLoading(false);
      });
  };

  const onImgClick = () => {
    window.location.href = "#/research";
  }

  const handleImgChange = (event) => {
    const name = event.target.id;

    const imgList = imgs.filter((item) => item.label === name)
    const img = imgList.length > 0 && imgList[0]["value"];
    setImgValue(img);
  };

  const classes = styles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm" style={{ padding: 0, maxWidth: "100%" }}>
        <Box sx={{ backgroundImage: `url(${image})` }} className={classes.img}>
          <Grid container spacing={2} style={{ padding: 50 }}>
            <Grid
              data-aos="fade-right"
              item
              xs={12}
              md={6}
              style={{
                margin: "auto",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              }}
            >
              <img
                data-aos="fade"
                className={classes.img}
                src={links.ANNAVARAM_GROUP_IMAGE2}
                alt={"ANNAVARAM GROUP IMAGE"}
              />
            </Grid>
            <Grid
              data-aos="fade-left"
              item
              xs={12}
              md={6}
              style={{ textAlign: "left" }}
            >
              <Content variant="h3" style={{ color: "#222222" }}>
                SCIP LAB @ USC
              </Content>
              <Content
                variant="body1"
                textAlign="left"
                style={{ color: "#222222" }}
              >
                Welcome to Professor <a href="https://viterbi.usc.edu/directory/faculty/Annavaram/Murali" target="_blank"> Murali Annavaram</a>'s Research Lab at USC. Our lab is nicknamed SCIP (Super Computing In Pocket)
              </Content>
              <Content
                variant="body1"
                textAlign="left"
                style={{ color: "#222222" }}
              >
                SCIP Research Areas:
              </Content>
              <ul className={classes.textStyle} style={{ color: '#222222' }}>
                <li> Energy efficiency through heterogeneous computing </li>
                <li> Reliability of high performance computing </li>
                <li> Bandwidth efficient big data computing </li>
                <li> Runtime systems design to enable dispersed computing </li> 
                <li> Hardware-assisted secure and private machine learning </li>
                <li> Building innovative sensor data collection platforms to improve human performance </li>. 
              </ul>
              <CustomButton>
                <a
                  href="#/about"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Learn More About Our Research
                </a>
              </CustomButton>
            </Grid>
          </Grid>
        </Box>

        <div className="scroll-bg" style={{ background: "white", padding: 30 }}>
          <Grid container>
            <Grid item xs={12} md={6} style={{ textAlign: "left" }}>
              <Content
                data-aos="fade-right"
                variant="h4"
                style={{ color: "#222222" }}
                textAlign="left"
              >
                Our Sponsors & Collaborators
              </Content>
              <Content
                variant="body1"
                style={{ color: "#222222" }}
                textAlign="left"
              >
                The center engages with researchers in industry and academia to tackle 
                compelling computer system challenges. We are fortunate to have been 
                consistently supported by our sponsors.
              </Content>
              <ul className={classes.textStyle} style={{ color: "#222222" }}>
                <li> DARPA FastNICs </li>
                <li> NSF DISCoVER Expeditions </li>
                <li> Intel Private AI Institute </li>
                <li> REAL@USC-META center </li>
              </ul>

              {/* <CustomButton>
                <a
                  href="#research"
                  style={{color: 'white', textDecoration: 'none'}}
                >
                  Learn More
                </a>
              </CustomButton> */}
            </Grid>
            <Grid item xs={12} md={6}>
            <video id="autoplay" muted playsinline controls width="100%" style={{ borderRadius: 10 }}>
                <source src={links.TOGETHER_VIDEO} type="video/mp4" />
                Sorry, your browser doesn't support embedded videos.
              </video>
            </Grid>
          </Grid>
        </div>

        <div className={classes.container1}>
          <Grid container>
            <Grid item xs={12} md={6} style={{ textAlign: "left" }}>
              <Content
                data-aos="fade-right"
                variant="h4"
                // style={{color: '#222222'}}
                textAlign="left"
              >
                Interested in SCIP Lab? 
              </Content>
              <Content
                variant="body1"
                // style={{color: '#222222'}}
                textAlign="left"
              > 
              Here are the skills we usually look for:              
              </Content>
              <ul className={classes.textStyle} style={{ color: 'white' }}>
                <li> Undergraduate senior level computer architecture </li>
                <li> Strong programming experience </li>
                <li> Hands-on debugging & experimentation (Does GDB scare you?) </li>
                <li> Prior research experience is a plus </li>
              </ul>

              {/* <CustomButton>
                <a
                  href="#research"
                  style={{color: 'white', textDecoration: 'none'}}
                >
                  Learn More
                </a>
              </CustomButton> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <video id="autoplay" muted playsinline controls width="100%" style={{ borderRadius: 10 }}>
                <source src={links.HOME_PAGE_POTTERY} type="video/mp4" />
                Sorry, your browser doesn't support embedded videos.
              </video>
            </Grid>
          </Grid>
        </div>

        <div id="research" className={classes.container2}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Content data-aos="fade-right" variant="h4" textAlign="left">
                Our Dimensions of Strength 
              </Content>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6}>
                <div
                  className={classes.linkText}
                  onMouseOver={handleImgChange}
                  onClick={onImgClick}
                  id="scalability"
                  variant="h5"
                  textAlign="left"
                >
                  Superconductive Computing <ArrowRightAltIcon fontSize="small" />
                </div>
                <div
                  className={classes.linkText}
                  onMouseOver={handleImgChange}
                  onClick={onImgClick}
                  id="scalability"
                  variant="h5"
                  textAlign="left"
                >
                  ML sclability and privacy <ArrowRightAltIcon fontSize="small" />
                </div>
                <div
                  className={classes.linkText}
                  onMouseOver={handleImgChange}
                  onClick={onImgClick}
                  id="privacy"
                  variant="h5"
                  textAlign="left"
                >
                  Energy Efficiency + Heterogeniety <ArrowRightAltIcon fontSize="small" />
                </div>
                <div
                  className={classes.linkText}
                  onMouseOver={handleImgChange}
                  onClick={onImgClick}
                  id="optimization"
                  variant="h5"
                  textAlign="left"
                >
                  Undergraduate research & outreach  <ArrowRightAltIcon fontSize="small" />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
              <video id="autoplay" muted playsinline controls width="100%" style={{ borderRadius: 10 }}>
                <source src={links.HOME_PAGE_PILLARS_IMG} type="video/mp4" />
                Sorry, your browser doesn't support embedded videos.
              </video>
            </Grid>
            </Grid>
          </Grid>
        </div>

        <div className={`scroll-bg + ${classes.container1}`}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Content variant="h4" textAlign="left">
                Teach
              </Content>
              <Content variant="body1" textAlign="left">
                Annavaram routines teaches computer architecture (EE557), advanced 
                topics in architecture (EE653), introduction to computer engineering (EE109), 
                and other special topics courses. Check 
                <a href="https://viterbigradadmission.usc.edu/sure/" target="_blank"> the USC 
                Schedule of Classes  </a>. Click below for example course syllabi. 
              </Content>
            </Grid>
            <Grid
              container
              style={{
                paddingTop: "30px",
                paddingBottom: 30,
                justifyContent: "center",
              }}
            >
              {teachSectionData.map((item, index) => {
                return (
                  <Grid item xs={12} md={4} key={`teach-${index}`}>
                    <TeachCard
                      title={item.title}
                      description={item.description}
                      link={item.link}
                      bgImg={item.bgImg}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </div>

        <div className={classes.container2}>
          <Grid container style={{ paddingTop: "30px", paddingBottom: 30 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Content variant="h4" textAlign="left">
                  Announcements
                </Content>
              </Grid>
              {!loading && (
                <>
                  {announcement.slice(0, 3).map((item, index) => {
                    return (
                      item.isVisible === "VISIBLE" && <Grid item xs={12} md={3} key={`announcement-${index}`}>
                        <AnnouncementCard
                          title={item.title}
                          date={item.date}
                          summary={item.summary}
                          description={item.description}
                          {...item}
                        />
                      </Grid>
                    );
                  })}
                  {announcement.length > 3 && (
                    <Grid item xs={12} md={3} style={{ margin: "auto" }}>
                      <Button
                        color="secondary"
                        variant="outlined"
                        href="#/events"
                      >
                        More Announcements
                      </Button>
                    </Grid>
                  )}
                  {announcement.length == 0 && (
                    <Content variant="body1">No Announcements</Content>
                  )}
                </>
              )}
              {loading && (
                <Grid item xs={12} md={3}>
                  <Box sx={{ pt: 0.5 }}>
                    <Skeleton />
                    <Skeleton width="60%" />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div> 

        <div className={classes.container1}>
          <Grid container>
            <Grid container style={{ paddingTop: "30px", paddingBottom: 30 }}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Grid item xs={12} md={12}>
                    <Content variant="h4" textAlign="left">
                      Events
                    </Content>
                  </Grid>
                  {!loading && (
                    <Grid container spacing={2}>
                      {events.slice(0, 3).map((item) => {
                        return (
                          item.isVisible === "VISIBLE" && (
                            <Grid item xs={12} md={3}>
                              <EventCard
                                title={item.title}
                                date={item.date}
                                description={item.description}
                                {...item}
                              />
                            </Grid>
                          )
                        );
                      })}
                      {events.length > 3 && (
                        <Grid item xs={12} md={3} style={{ margin: "auto" }}>
                          <Button
                            color="secondary"
                            variant="outlined"
                            href="#/events"
                          >
                            More Events
                          </Button>
                        </Grid>
                      )}
                      {events.length == 0 && (
                        <Content variant="body1">No Events</Content>
                      )}
                    </Grid>
                  )}
                  {loading && (
                    <Grid item xs={12} md={3}>
                      <Box sx={{ pt: 0.5 }}>
                        <Skeleton />
                        <Skeleton width="60%" />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        {/* <div className={classes.container1}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Content variant="h4" textAlign="left">
                Want More Information?
              </Content>
              <a href="#/contact" style={{ textDecoration: "none" }}>
                <div
                  className={classes.linkText}
                  id="contact"
                  variant="h5"
                  textAlign="left"
                >
                  Contact Us <ArrowRightAltIcon fontSize="small" />
                </div>
              </a>
            </Grid>
          </Grid>
        </div> */}
      </Container>
      <Footer />
    </React.Fragment>
  );
}
