import {links} from './links';

export const homeData = {
  title: 'SCIP-LAB',
  /*content:
    'The Center for Research and Education in AI and Learning (REAL@USC) will advance foundations for cooperative algorithmic optimization and hardware innovations for AI, and advance AI education accessibility.  This Center will focus on three broad categories of activities: (1) AI research at the intersection of systems hardware and algorithms, with particular emphasis on  benchmarking and evaluation strategies for AI algorithms running on a broad range of computing platforms, computational optimization to formalize the AI model training process under practical constraints, and other related AI infrastructure research projects; (2) developing AI curriculum to keep pace with the research advances, and (3) expanding AI education opportunities to diverse group of students to enable ML careers.',*/
};

export const teachSectionData = [
  {
    title: 'EE-109',
    description: 'Introduction to Comp Eng',
    link: '#/teach',
    bgImg: links.HOME_PAGE_OUTREACH_UG,
  },
  {
    title: 'EE-557',
    description: 'Graduate Level Computer Architecture',
    link: '#/teach',
    bgImg: links.HOME_PAGE_OUTREACH_UG,
  },
  {
    title: 'EE-653',
    description: 'Special Topics in Computer Systems',
    link: '#/teach',
    bgImg: links.HOME_PAGE_OUTREACH_UG,
  },
];

export const outreachSectionData = [
  {
    title: 'K-12',
    description: 'More Info about K-12',
    link: '#/outreach',
    bgImg: links.HOME_PAGE_OUTREACH_K12,
  },
  {
    title: 'Undergraduate',
    description: 'More Info about Undergraduate',
    link: '#/outreach',
    bgImg: links.HOME_PAGE_OUTREACH_UG,
  },
  {
    title: 'Graduate',
    description: 'More Info about MS',
    link: '#/outreach',
    bgImg: links.HOME_PAGE_OUTREACH_MS,
  },
  {
    title: 'PHD',
    description: 'More Info about PHD',
    link: '#/outreach',
    bgImg: links.HOME_PAGE_OUTREACH_PHD,
  },
  /*{
    title: 'Curriculum Development',
    description: 'More Info about Curriculum development',
    link: '/teach',
    bgImg: links.HOME_PAGE_OUTREACH_CD,
  },*/
  {
    title: 'Workshops',
    description: 'More Info about workshop',
    link: '#/outreach',
    bgImg: links.HOME_PAGE_OUTREACH_WORKSHOPS,
  },
];
